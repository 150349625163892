<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-12">
          <h1>Add a video</h1>
          <p class="caption">
            Important: This adds a video directly in DB. Please do it only after
            you have all the needed data
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-card class="pa-5">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Title*"
                v-model="videoTitle"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select
                :items="loadedSpeakers"
                v-model="selectedSpeaker"
                name="speaker"
                item-text="last_name"
                label="Select a speaker"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-switch
                v-model="videoPaidOnly"
                class="ma-2"
                label="Paid Only*"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-switch
                v-model="videoIsTrailer"
                class="ma-2"
                label="Is Trailer*"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <datetime
                class="datetime-picker"
                type="datetime"
                v-model="datetime"
              ></datetime>
            </v-col>
            <v-col cols="12" sm="12" md="10">
              <v-text-field
                label="Description*"
                v-model="videoDescription"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-text-field
                label="# Posizione*"
                v-model="videoPositionIndex"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                label="Video Cover Image*"
                hint="Jpeg, jpg, png. Possibilmente xx * yy pixel"
                persistent-hint
                prepend-icon="mdi-camera"
                v-model="videoCoverImage"
              ></v-file-input>
            </v-col>

            <v-col cols="12" sm="12">
              <v-file-input
                accept="video/*"
                label="Video"
                hint="Possibilmente sotto 200mb."
                persistent-hint
                prepend-icon="mdi-video"
                v-model="videoFile"
              ></v-file-input>
            </v-col>
            <!-- <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Video 1280x720"
                v-model="videoSource720"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Video 960x540"
                v-model="videoSource540"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Video 640x360"
                v-model="videoSource360"
              ></v-text-field>
            </v-col> -->
          </v-row>
        </v-card>
      </v-row>
      <v-row class="mt-5 pr-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="addSpeakerVideo"
          :disabled="
            !videoTitle ||
            !datetime ||
            !selectedSpeaker ||
            !videoCoverImage ||
            !videoFile
          "
          large
          >Aggiungi Video</v-btn
        >
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="success"
      top
      right
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />
  </div>
</template>

<script>
import { fb, storage, videosCollection } from "@/plugins/firebase.js";

export default {
  name: "AdminAddSpeaker",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      videoTitle: null,
      selectedSpeaker: null,
      videoPaidOnly: true,
      videoIsTrailer: false,
      videoDescription: null,
      videoPositionIndex: null,
      videoCoverImage: null,
      videoFile: null,
      GsUrlCover: null,
      GsUrlVideo: null,
      datetime: null,
      videoSource720: null,
      videoSource540: null,
      videoSource360: null,
      highlights: [],

      snackbar: false,
      snackbarText: "Speaker aggiunto con successo",
      snackbarTimeout: 5000,
      inProgress: false,
      overlaymessage: "Uploading in progress.",
      overlaymessage2: "",
    };
  },
  methods: {
    async addSpeakerVideo() {
      try {
        // --------------------------------------------------------
        // Step 0 - start spinner
        // --------------------------------------------------------
        this.inProgress = true;

        // --------------------------------------------------------
        // Step 1 - Create document in database
        // --------------------------------------------------------

        const videoData = {
          title: this.videoTitle,
          main_speaker: this.selectedSpeaker.slug,
          main_speakerId: this.selectedSpeaker.id,
          paid_only: this.videoPaidOnly,
          isTrailer: this.videoIsTrailer,
          description: this.videoDescription,
          positionIndex: this.videoPositionIndex,
          datetime: this.datetime,
          highlights: this.highlights,
          videoSource: this.videoSource,
          created_at: fb.firestore.FieldValue.serverTimestamp(),
        };

        const videoCreated = await videosCollection.add(videoData);
        this.videoId = videoCreated.id;

        console.log("speaker was added in db");

        // --------------------------------------------------------
        // Step 2 - Upload image file in storage (folder speakers/speaker_id/*_cover)
        // --------------------------------------------------------

        const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload = "speakers/";
        const pathFile =
          this.selectedSpeaker.id + "/" + this.videoId + "_cover";

        console.log(pathFile);

        const fileRef = storage.ref().child(folderUpload + pathFile);
        await fileRef.put(this.videoCoverImage);

        console.log("image was uploaded");

        this.GsUrlCover = pathStorage + folderUpload + pathFile;

        // --------------------------------------------------------
        // Step 3 - Upload video file in storage (folder speakers/speaker_id/*)
        // --------------------------------------------------------

        const pathStorage1 = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload1 = "speakers/";
        const pathFile1 = this.selectedSpeaker.id + "/" + this.videoId;

        const fileRef1 = storage
          .ref()
          // .child(folderUpload1 + pathFile1);
          .child(folderUpload1 + pathFile1);
        await fileRef1.put(this.videoFile);

        console.log("video was uploaded");

        this.GsUrlVideo = pathStorage1 + folderUpload1 + pathFile1;

        // --------------------------------------------------------
        // Step 4 - Update document in database to include image url
        // --------------------------------------------------------

        const videoDataToAdd = {
          cover: this.GsUrlCover,
          src_video: this.GsUrlVideo,
        };

        await videosCollection
          .doc(this.videoId)
          .set(videoDataToAdd, { merge: true });

        // --------------------------------------------------------
        // Step 5 - Clear form and notify user
        // --------------------------------------------------------

        // then empty form
        this.videoTitle = null;
        this.selectedSpeaker = null;
        this.videoDescription = null;
        this.speakerBioShort = null;
        this.videoCoverImage = null;
        this.videoFile = null;
        this.GsUrlCover = null;
        this.GsUrlVideo = null;
        this.videoSource720 = null;
        this.videoSource540 = null;
        this.videoSource360 = null;

        // Close spinner
        this.inProgress = false;

        // Notify user the action was done with snackbar
        this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    loadedSpeakers() {
      return this.$store.getters.loadedSpeakers;
    },
    videoSource() {
      let source = [
        {
          src: this.videoSource360,
          resolution: "360p",
        },
        {
          src: this.videoSource540,
          resolution: "540p",
        },
        {
          src: this.videoSource720,
          resolution: "720p",
        },
      ];
      return source;
    },
  },
};
</script>

<style scoped>
.datetime-picker {
  border: 1px solid grey;
}
</style>