<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-12 white--text">
          <h1>
            Edit video
            <span v-if="video">{{ video.title }}</span>
          </h1>
          <p class="caption">
            Video id: {{ video.id }} <br />
            Important: This adds a video directly in DB. Please do it only after
            you have all the needed data
          </p>
        </v-col>
      </v-row>
      <div v-if="video">
        <v-row>
          <v-layout child-flex>
            <v-card class="pa-5">
              <h2>Overview</h2>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Title*"
                    v-model="video.title"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <!-- <v-select
                  :items="loadedSpeakers"
                  v-model="selectedSpeaker"
                  name="speaker"
                  item-text="first_name"
                  label="Select a speaker"
                  return-object
                  disabled
                  ></v-select>-->
                  <v-text-field
                    label="Speaker"
                    v-model="video.main_speaker"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-switch
                    v-model="video.paid_only"
                    class="ma-2"
                    label="Paid Only*"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-switch
                    v-model="video.isTrailer"
                    class="ma-2"
                    label="Is Trailer*"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <datetime
                    class="datetime-picker"
                    type="datetime"
                    v-model="video.datetime"
                  ></datetime>
                </v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field
                    label="Description*"
                    v-model="video.description"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field
                    label="# Posizione*"
                    v-model="video.videoPositionIndex"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    label="Video Cover Image*"
                    hint="Jpeg, jpg, png. Possibilmente xx * yy pixel"
                    persistent-hint
                    prepend-icon="mdi-camera"
                    v-model="videoCoverImage"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    accept="video/*"
                    label="Video*"
                    hint="Possibilmente sotto 200mb."
                    persistent-hint
                    prepend-icon="mdi-video"
                    v-model="videoFile"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="`Video ${video.videoSource[2].resolution}`"
                    v-model="video.videoSource[2].src"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="`Video ${video.videoSource[1].resolution}`"
                    v-model="video.videoSource[1].src"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="`Video ${video.videoSource[0].resolution}`"
                    v-model="video.videoSource[0].src"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-row>

        <v-row class="mt-3">
          <v-layout child-flex>
            <v-card class="pa-5">
              <h2>Index / Domande Risposte</h2>
              <div class="d-flex">
                <v-text-field
                  label="Question or Index item"
                  v-model="questionToAddText"
                  class="ma-2"
                ></v-text-field>
                <v-text-field
                  label="Time (sec)"
                  v-model="questionToAddTime"
                  class="ma-2"
                ></v-text-field>
                <v-btn @click="addQuestion" class="ma-2">Add</v-btn>
              </div>
              <!-- <p v-for="(item, index) in video.highlights" :key="index">{{item.text}}</p> -->
              <v-data-table
                :headers="headers"
                :items="video.highlights"
                :items-per-page="15"
              >
                <template v-slot:item.text="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.text"
                    @save="save"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ props.item.text }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.text"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.time="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.time"
                    @save="save"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                  >
                    {{ props.item.time }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.time"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.action="props">
                  <v-btn icon color="red" @click="deleteQuestion(props.item)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-data-table>

              <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                  <v-btn v-bind="attrs" text @click="snack = false"
                    >Close</v-btn
                  >
                </template>
              </v-snackbar>
            </v-card>
          </v-layout>
        </v-row>
        <v-row class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="editVideo" large>Edit Video</v-btn>
        </v-row>
      </div>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="success"
      top
      right
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />
  </div>
</template>

<script>
import { storage, videosCollection } from "@/plugins/firebase.js";

export default {
  name: "EditSpeaker",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      video: null,
      selectedSpeaker: null,
      videoCoverImage: null,
      videoFile: null,

      GsUrlCover: null,
      GsUrlVideo: null,

      snackbar: false,
      snackbarText: "Speaker aggiunto con successo",
      snackbarTimeout: 5000,
      inProgress: false,
      overlaymessage: "Uploading in progress.",
      overlaymessage2: "",

      questionToAddText: null,
      questionToAddTime: null,
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      headers: [
        {
          text: "Text",
          align: "start",
          value: "text",
        },
        { text: "Time (secs)", value: "time" },
        { text: "Action", value: "action" },
      ],
    };
  },

  computed: {},

  methods: {
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      // this.snack = true;
      // this.snackColor = "info";
      // this.snackText = "Dialog opened";
    },
    close() {
      // console.log("Dialog closed");
    },
    deleteQuestion(item) {
      var removeByAttr = function (arr, attr, value) {
        var i = arr.length;
        while (i--) {
          if (
            arr[i] &&
            // eslint-disable-next-line no-prototype-builtins
            arr[i].hasOwnProperty(attr) &&
            arguments.length > 2 &&
            arr[i][attr] === value
          ) {
            arr.splice(i, 1);
          }
        }
        return arr;
      };

      var arr = this.video.highlights;
      removeByAttr(arr, "time", item.time);
    },
    addQuestion() {
      const videoQuestionToAdd = {
        text: this.questionToAddText,
        time: this.questionToAddTime,
      };

      this.video.highlights.push(videoQuestionToAdd);

      this.questionToAddText = null;
      this.questionToAddTime = null;
    },
    async editVideo() {
      try {
        // --------------------------------------------------------
        // Step 0 - start spinner
        // --------------------------------------------------------
        this.inProgress = true;

        // --------------------------------------------------------
        // Step 2 - Upload image file in storage (folder speakers/speaker_id/*_cover)
        // --------------------------------------------------------
        const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload = "speakers/";
        const pathFile =
          this.video.main_speakerId + "/" + this.video.id + "_cover";

        // console.log(pathFile);

        const fileRef = storage.ref().child(folderUpload + pathFile);

        if (this.videoCoverImage) {
          await fileRef.put(this.videoCoverImage);
          console.log("image was uploaded");
        }

        this.GsUrlCover = pathStorage + folderUpload + pathFile;

        // --------------------------------------------------------
        // Step 3 - Upload video file in storage (folder speakers/speaker_id/*)
        // --------------------------------------------------------
        // const pathStorage1 = "gs://techsoup-together-frontend-dev/";
        const pathStorage1 = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload1 = "speakers/";
        const pathFile1 = this.video.main_speakerId + "/" + this.video.id;

        const fileRef1 = storage
          .ref()
          // .child(folderUpload1 + pathFile1);
          .child(folderUpload1 + pathFile1);
        if (this.videoFile) {
          await fileRef1.put(this.videoFile);
          console.log("video was uploaded");
        }

        this.GsUrlVideo = pathStorage1 + folderUpload1 + pathFile1;

        // --------------------------------------------------------
        // Step 4 - Update document in database to include image url
        // --------------------------------------------------------

        const videoDataToAdd = this.video;
        videoDataToAdd.cover = this.GsUrlCover;
        videoDataToAdd.src_video = this.GsUrlVideo;

        await videosCollection
          .doc(this.video.id)
          .set(videoDataToAdd, { merge: true });

        // --------------------------------------------------------
        // Step 5 - Clear form and notify user
        // --------------------------------------------------------

        // then empty form
        this.videoCoverImage = null;
        this.videoFile = null;
        this.GsUrlCover = null;
        this.GsUrlVideo = null;
        this.videoPositionIndex = null;

        // Close spinner
        this.inProgress = false;

        // Notify user the action was done with snackbar
        this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    var docRef = videosCollection.doc(this.$route.params.id);

    let doc = await docRef.get();
    if (doc.exists) {
      this.video = doc.data();
      this.video.id = this.$route.params.id;
      return doc.data();
    }
    throw new Error("No such document");
  },
};
</script>

<style>
</style>