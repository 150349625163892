<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="col-12">
          <h1>Add a speaker</h1>
          <p class="caption">
            Important: This adds a speaker directly in DB. Please do it only
            after you have all the needed data
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-card class="pa-5">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="First name*"
                v-model="speakerFirstName"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Last name*"
                v-model="speakerLastName"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Slug*"
                hint="es. name_surname"
                v-model="speakerSlug"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Organization*"
                v-model="speakerOrganization"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Role*"
                v-model="speakerRole"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Bio short*"
                v-model="speakerBioShort"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Bio long*"
                v-model="speakerBioLong"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="[
                  'Fundraising',
                  'Infrastruttura IT',
                  'Team management',
                  'Smart working',
                  'Innovazione',
                  'Web marketing',
                  'Cloud computing',
                  'Project management',
                  'Leadership',
                  'Social media',
                  'Terzo settore',
                  'Sostenibilità',
                ]"
                label="Areas of expertise*"
                hint="Importante: mettere per prima la principale"
                persistent-hint
                multiple
                v-model="speakerAreasOfExpertise"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                label="Speaker photo*"
                hint="Jpeg, jpg, png. Possibilmente quadrata."
                persistent-hint
                prepend-icon="mdi-camera"
                v-model="speakerImage"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <v-row class="mt-5 pr-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="CreateSpeaker" large
          >Aggiungi Speaker</v-btn
        >
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      color="success"
      top
      right
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />
  </div>
</template>

<script>
import { fb, speakersCollection } from "@/plugins/firebase.js";

export default {
  name: "AdminAddSpeaker",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      speakerFirstName: null,
      speakerLastName: null,
      speakerSlug: null,
      speakerOrganization: null,
      speakerRole: null,
      speakerBioShort: null,
      speakerBioLong: null,
      speakerAreasOfExpertise: null,
      speakerImage: null,
      speakerId: null,
      GsUrlOriginal: null,
      snackbar: false,
      snackbarText: "Speaker aggiunto con successo",
      snackbarTimeout: 5000,
      inProgress: false,
      overlaymessage: "Uploading in progress.",
      overlaymessage2: "",
    };
  },
  methods: {
    async CreateSpeaker() {
      try {
        // --------------------------------------------------------
        // Step 0 - start spinner
        // --------------------------------------------------------
        this.inProgress = true;

        // --------------------------------------------------------
        // Step 1 - Create document in database
        // --------------------------------------------------------

        const speakerData = {
          first_name: this.speakerFirstName,
          last_name: this.speakerLastName,
          slug: this.speakerSlug,
          organization: this.speakerOrganization,
          role: this.speakerRole,
          bio_short: this.speakerBioShort,
          bio_long: this.speakerBioLong,
          areas_of_expertise: this.speakerAreasOfExpertise,
          // photo_url: this.GsUrlOriginal
        };

        const speakerCreated = await speakersCollection.add(speakerData);
        this.speakerId = speakerCreated.id;

        // --------------------------------------------------------
        // Step 2 - Upload image file in storage (folder speakers/speaker_id/*)
        // --------------------------------------------------------
        const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload = "speakers/";
        const pathFile = this.speakerId + "/" + "speaker_image-original";

        const fileRef = fb
          .storage()
          .ref()
          .child(folderUpload + pathFile);
        await fileRef.put(this.speakerImage);

        this.GsUrlOriginal = pathStorage + folderUpload + pathFile;

        // --------------------------------------------------------
        // Step 3 - Update document in database to include image url and document id
        // --------------------------------------------------------

        const speakerDataToAdd = {
          photo_url: this.GsUrlOriginal,
          id: this.speakerId,
        };

        await speakersCollection
          .doc(this.speakerId)
          .set(speakerDataToAdd, { merge: true });

        // --------------------------------------------------------
        // Step 3 - Clear form and notify user
        // --------------------------------------------------------

        // then empty form
        this.speakerFirstName = null;
        this.speakerLastName = null;
        this.speakerSlug = null;
        this.speakerBioShort = null;
        this.speakerBioLong = null;
        this.speakerAreasOfExpertise = null;
        this.speakerImage = null;

        // Close spinner
        this.inProgress = false;

        // Notify user the action was done with snackbar
        this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>