<template>
  <div id="editSpeaker">
    <v-container v-if="singleSpeaker">
      <v-row>
        <v-col class="col-12">
          <h1>Edit speaker: {{singleSpeaker.first_name}} {{singleSpeaker.last_name}}</h1>
          <p
            class="caption"
          >Important: This edits the speaker directly in DB. Please do it only after you have all the needed data</p>
        </v-col>
      </v-row>
      <v-row>
        <v-card class="pa-5">
          <h2>General info</h2>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field label="First name*" v-model="singleSpeaker.first_name" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field label="Last name*" v-model="singleSpeaker.last_name" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Slug*"
                hint="es. name_surname"
                v-model="singleSpeaker.slug"
                persistent-hint
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Organization*" v-model="singleSpeaker.organization" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Role*" v-model="singleSpeaker.role" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Bio short*" v-model="singleSpeaker.bio_short" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field label="Bio long*" v-model="singleSpeaker.bio_long" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="['Fundraising', 'Infrastruttura IT', 'Team management', 'Smart working', 'Innovazione', 'Web marketing', 'Cloud computing', 'Project management', 'Leadership', 'Social media', 'Terzo settore', 'Sostenibilità']"
                label="Areas of expertise*"
                hint="Importante: mettere per prima la principale"
                persistent-hint
                multiple
                v-model="singleSpeaker.areas_of_expertise"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                label="Speaker photo"
                hint="Jpeg, jpg, png. Possibilmente quadrata."
                persistent-hint
                prepend-icon="mdi-camera"
                v-model="speakerImage"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row class="mt-5 mb-10">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="editSpeaker" large>Edit Speaker</v-btn>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="success" top right>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
    />
  </div>
</template>

<script>
import { fb, speakersCollection } from "@/plugins/firebase.js";

export default {
  name: "EditSpeaker",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      speakerImage: null,
      GsUrlOriginal: null,
      snackbar: false,
      snackbarText: "Speaker modificato con successo",
      snackbarTimeout: 5000,
      inProgress: false,
      overlaymessage: "Uploading in progress.",
      overlaymessage2: "",
    };
  },

  computed: {
    singleSpeaker() {
      return this.$store.getters.singleSpeaker(this.$route.params.slug);
    },
  },

  methods: {
    async editSpeaker() {
      try {
        // --------------------------------------------------------
        // Step 0 - start spinner
        // --------------------------------------------------------
        this.inProgress = true;

        // --------------------------------------------------------
        // Step 1 - Upload image file in storage (folder speakers/speaker_id/*) if image was selected
        // --------------------------------------------------------

        if (this.speakerImage) {
          const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
          const folderUpload = "speakers/";
          const pathFile =
            this.singleSpeaker.id + "/" + "speaker_image-original";

          const fileRef = fb
            .storage()
            .ref()
            .child(folderUpload + pathFile);
          await fileRef.put(this.speakerImage);

          this.GsUrlOriginal = pathStorage + folderUpload + pathFile;
        }

        // --------------------------------------------------------
        // Step 2 - Update document in database to include image url and document id
        // --------------------------------------------------------

        const speakerDataToAdd = this.singleSpeaker;
        (speakerDataToAdd.photo_url = this.GsUrlOriginal),
          await speakersCollection
            .doc(this.singleSpeaker.id)
            .set(speakerDataToAdd, { merge: true });

        // --------------------------------------------------------
        // Step 3 - Clear form and notify user
        // --------------------------------------------------------

        this.speakerImage = null;

        // Close spinner
        this.inProgress = false;

        // Notify user the action was done with snackbar
        this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>