<template>
  <v-container class="fill-height" align="center" justify="center" fluid>
    <v-row>
      <v-text-field
        label="Email"
        name="email"
        prepend-icon="mdi-email"
        type="email"
        v-model="emailAdminToAdd"
      />
      <p>{{emailAdminToAdd}}</p>
      <v-btn @click="AddAsAdmin">AddAdmin</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { fbfunction } from "@/plugins/firebase.js";

export default {
  name: "AddAdmin",

  data() {
    return {
      emailAdminToAdd: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    AddAsAdmin() {
      var addAdmin = fbfunction.httpsCallable("addAdmin");
      addAdmin({ email: this.emailAdminToAdd }).then(function (result) {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data.text;
        console.log(sanitizedMessage);
      });
    },
  },
};
</script>

<style>
</style>







