<template>
  <div class="settings" >
    <v-container class="pt-10">
      <v-row class="py-2 px-3 pt-1">
        <v-responsive class="overflow-visible">
          <h1 class="subheading grey--text">
              Users
          </h1>
        </v-responsive>
        <v-btn icon
              color="grey mr-5"
              @click.stop="fetchOrganizations" >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
      </v-row>
      <v-row align="start" justify="start">
        <v-col cols="12" sm="12" md="12">
          <v-data-table v-if="orgs"
              :headers="headers"
              :items="orgs"
              :search="search"
              class="app_background"
              @click:row="seeDetails"
            >
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { fb, usersCollection } from "@/plugins/firebase.js";

export default {
  name: "Organizations",
  title: "Orgs",

  data() {
    return {
      orgs: null,
      parentOrgs: null,

      show: false,
      search: "",
      headers: [
        {
          text: "NAME",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "USER_ID", value: "id" },
        { text: "DATE", value: "created_at" },
      ],

      dialogAddOrganization: false,
      newOrganizationName: null,
      isChildOrg: false,
      selectedParentOrg: null,

      feedback: null,
    };
  },
  computed: {
    // loadedPosts() {
    //   return this.$store.getters.loadedPosts;
    // },
  },
  methods: {
    updateCreatedAt(uid) {
      var addAdmin = fb.app().functions("europe-west2").httpsCallable('adminUpdateUserCreatedAt');
      addAdmin({ uid: uid }).then(function (result) {
        // Read result of the Cloud Function.
        var sanitizedMessage = result.data.result;
        console.log(sanitizedMessage);
        console.log('done')
      });
    },
    seeDetails(value) {
      // this.$router.push(`/orgs/${value.id}`);
      console.log(value.id)
      // this.updateCreatedAt(value.id)
    },
    async fetchOrganizations(){
      const orgs = [];
      await usersCollection
      // .where('type', '!=', 'nested-parent')
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            let singleorg = doc.data();
            singleorg.id = doc.id
            orgs.push(singleorg)
        });
      }).catch((err) => console.log(err) );
      this.orgs = orgs
    },
  },
  created(){
    this.fetchOrganizations()
  }
};
</script>

<style>
</style>