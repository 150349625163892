<template>
  <div id="adminHome">
    <v-container>
      <v-row>
        <v-col class="col-6">
          <div class="d-flex">
            <h1 class="mb-5">Speakers</h1>
            <v-spacer></v-spacer>
            <v-btn @click="addSpeaker" class="primary">Add Speaker</v-btn>
          </div>
          <v-card class="mx-auto" tile>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="speaker in loadedSpeakers"
                  :key="speaker.id"
                  @click="editSpeaker(speaker)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ speaker.first_name }}
                      {{ speaker.last_name }}</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col class="col-6">
          <div class="d-flex">
            <h1 class="mb-5">Videos</h1>
            <v-spacer></v-spacer>
            <v-btn @click="addVideo" class="primary">Add Video</v-btn>
          </div>
          <v-card class="mx-auto" tile>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="video in allVideos"
                  :key="video.id"
                  @click="editVideo(video)"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ video.videoPositionIndex }} |
                      {{ video.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ video.main_speaker }} | isTrailer:
                      {{ video.isTrailer }} | paid_only:
                      {{ video.paid_only }}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <!-- <v-list-item v-for="video in allVideos" :key="video.id" @click="editSpeaker(video)">
                  <v-list-item-content>
                    <v-list-item-title>{{video.id}}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                </v-list-item>-->
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { videosCollection } from "@/plugins/firebase.js";

export default {
  name: "AdminHome",

  data: () => ({
    allVideos: [],
  }),

  methods: {
    addSpeaker() {
      this.$router.push("/dear_mama_admin/add_speaker");
    },
    addVideo() {
      this.$router.push("/dear_mama_admin/add_speaker_video");
    },
    editSpeaker(speaker) {
      this.$router.push(`/dear_mama_admin/edit_speaker/${speaker.slug}`);
    },
    editVideo(video) {
      this.$router.push(`/dear_mama_admin/edit_video/${video.id}`);
    },
  },

  computed: {
    loadedSpeakers() {
      return this.$store.getters.loadedSpeakers;
    },
  },

  async created() {
    const videoresults = await videosCollection.orderBy("created_at").get();

    const allVideos = [];

    videoresults.forEach(async (doc) => {
      let singleVideo = doc.data();
      singleVideo.id = doc.id;

      allVideos.push(singleVideo);
    });

    this.allVideos = allVideos;
  },
};
</script>

<style>
</style>